import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import Icon from "../components/Icon"

import workshopStaff from "../images/workshop/workshop-staff.jpg"

const Workshop = ({ location }) => (
  <Layout location={location}>
    <SEO title="Workshop" />
    <div className="row">
      <div className="col-md-8 content-left">
        <h1>Workshop</h1>
        <p>
          When you deal with the ML Wray workshop you are assured of the best
          customer service.
        </p>
        <p>
          This means we provide you with a written estimate upon request, we
          provide prompt, efficient repairs, all repairs are carried out in
          accordance with accepted trade practice, we provide an itemised
          invoice on completion of your repairs, all replaced parts - excluding
          items traded on an exchange basis - are returned to you, and all of
          our repairs are covered by a minimum 500km or three month warranty!
        </p>
        <div className="col-12 content-nav">
          <ul className="nav flex-column">
            <li>
              <Icon name="chevron-right" />
              Rego Inspections (Pink &amp; Blue Slips)
            </li>
            <li>
              <Icon name="chevron-right" />
              Servicing of Most Makes &amp; Models (including log book
              servicing)
            </li>
            <li>
              <Icon name="chevron-right" />
              Clutch &amp; Brake Repairs
            </li>
            <li>
              <Icon name="chevron-right" />
              Disc &amp; Drum Machining
            </li>
            <li>
              <Icon name="chevron-right" />
              General Mechanical Repairs
            </li>
            <li>
              <Icon name="chevron-right" />
              Free Pick-up &amp; Delivery in local area
            </li>
          </ul>
        </div>
      </div>
      <div className="col-md-4 content-right">
        <h1>Get in touch!</h1>
        <p>
          <a href="tel:0244220454">
            <Icon name="phone" />
            (02) 4422 0454
          </a>
        </p>
        <p>
          <Link to="/contact" className="btn btn-primary">
            Online Enquiry
            <Icon name="arrow-right" />
          </Link>
        </p>
        <p>Licence No: MVRL24142</p>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <img
          className="content-image"
          src={workshopStaff}
          alt="ML Wray Automotive Workshop Staff"
        />
      </div>
    </div>
  </Layout>
)

export default Workshop
